import Image, { type StaticImageData } from 'next/image'
import { type FC, type HTMLAttributeAnchorTarget } from 'react'
import { useTheme } from 'styled-components'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { useLocale } from '@/hooks/useLocale'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'
import { formatPrice } from '@/utils/kijiji-common/money'
import ImageFallback from '~/public/next-assets/images/not-found.webp'

import { ImageCustom } from '../image'
import FavouriteButton from '../listing/FavouriteButton'
import {
  VerticalCategoryListingBody,
  VerticalCategoryListingContainer,
  VerticalCategoryListingHeader,
  VerticalListingCardContainer,
  VerticalListingCardHeadline,
  VerticalListingCardImage,
  VerticalListingCardImageWrapper,
} from './styled'
import { type AllowFavouriteAction, type StaticCardDimension } from './types'

export type VerticalListingCardProps = AllowFavouriteAction &
  StaticCardDimension & {
    /**
     * Description - Added to card when it is a category one
     */
    description?: string
    /**
     * Function triggered on card click
     */
    handleClick?: () => void
    /**
     * Listing card Image
     */
    listingImage?: {
      /**
       * Image source
       * It should be of type StaticImageData if comes from next-assets CDN
       * If src comes from our API, then should accept string
       */
      src?: StaticImageData | string | null
      /**
       * Image alt attribute
       */
      alt?: string | null
    }
    /**
     * Target attribute for the listing link
     */
    linkTarget?: HTMLAttributeAnchorTarget
    /**
     * Variation of the listing image size
     * @default 'regular'
     */
    listingImageSize?: 'small' | 'regular'
    /**
     * Sizes or breakpoints for the NextJS Image component
     * https://nextjs.org/docs/pages/api-reference/components/image#sizes
     * */
    nextImageSizes?: string
    /**
     * Price in cents being returned by the BE
     * */
    price?: number | null
    /**
     * Listing card seo link
     * */
    seoUrl: string
    /**
     * Listing card title
     */
    title: string
    /**
     * Card variant style
     * @default listing
     */
    variant?: 'listing' | 'category'
  }

export const VerticalListingCard: FC<VerticalListingCardProps> = ({
  allowFavourite,
  description,
  handleClick,
  itemsPerRow,
  linkTarget = '_self',
  listingId,
  listingImage,
  listingImageSize = 'regular',
  minWidth,
  nextImageSizes = '15rem',
  price,
  seoUrl,
  title,
  variant = 'listing',
}) => {
  const { spacing, colors } = useTheme()
  const { apiLocale } = useLocale()

  return (
    <VerticalListingCardContainer
      itemsPerRow={itemsPerRow}
      listingImageSize={listingImageSize}
      minWidth={minWidth}
      variant={variant}
    >
      <LinkCustom href={seoUrl} noStyle onClick={handleClick} target={linkTarget}>
        {variant === 'listing' ? (
          <>
            <div>
              <VerticalListingCardImageWrapper $listingImageSize={listingImageSize}>
                <VerticalListingCardImage
                  $listingImageSize={listingImageSize}
                  alt={listingImage?.alt || ''}
                  src={
                    typeof listingImage?.src === 'string'
                      ? listingImage?.src
                      : '/next-assets/images/not-found.jpg'
                  }
                  sizes={nextImageSizes}
                  layout="fill"
                  unoptimized
                />
              </VerticalListingCardImageWrapper>

              <Spacing
                mBottom={spacing.defaultSmall}
                mLeft={spacing.default}
                mRight={spacing.default}
                mTop={spacing.defaultSmall}
              >
                <VerticalListingCardHeadline
                  color={colors.grey.primary}
                  forwardedAs="h3"
                  size="small"
                >
                  {title}
                </VerticalListingCardHeadline>
              </Spacing>
            </div>

            <Spacing mLeft={spacing.default} mBottom={spacing.default} mRight={spacing.default}>
              <Flex justifyContent={price ? 'space-between' : 'flex-end'}>
                {!!price && (
                  <BodyText color={colors.green.dark1} size="small">
                    {formatPrice(price, { isFrench: apiLocale === 'fr_CA' })}
                  </BodyText>
                )}

                {allowFavourite && listingId && <FavouriteButton listingId={listingId} />}
              </Flex>
            </Spacing>
          </>
        ) : (
          <VerticalCategoryListingContainer>
            {typeof listingImage?.src === 'string' ? (
              <ImageCustom
                alt={listingImage?.alt || ''}
                layout="fill"
                src={listingImage.src}
                unoptimized
              />
            ) : (
              <Image
                fill
                alt={listingImage?.alt || ''}
                src={listingImage?.src || ImageFallback}
                unoptimized
              />
            )}

            <VerticalCategoryListingBody>
              <VerticalCategoryListingHeader
                forwardedAs="h3"
                size="xSmall"
                color={colors.purple.light4}
                weight="regular"
              >
                {title}
              </VerticalCategoryListingHeader>
              <BodyText size="medium" color={colors.white} textAlign="center">
                {description}
              </BodyText>
            </VerticalCategoryListingBody>
          </VerticalCategoryListingContainer>
        )}
      </LinkCustom>
    </VerticalListingCardContainer>
  )
}
