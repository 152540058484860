import { Skeleton } from '@/ui/atoms/skeleton'

import { LoadingGalleryCardBody, LoadingGalleryCardContainer } from './styled'

export const LoadingListingCard = () => {
  return (
    <LoadingGalleryCardContainer>
      <Skeleton count={1} width="100%" height="14rem" variant="rectangle" />
      <LoadingGalleryCardBody>
        <Skeleton count={1} variant="inline" height="1rem" width="6rem" />
        <Skeleton count={1} variant="inline" height="1rem" width="12rem" />
        <Skeleton count={1} variant="inline" height="1rem" width="7rem" />
      </LoadingGalleryCardBody>
    </LoadingGalleryCardContainer>
  )
}
