import InfoTooltip from '@kijiji/icons/src/icons/InfoToolTip'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { GalleryHeaderWrapper } from '@/components/shared/gallery/GalleryHeaderWrapper'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { useTooltipVisibility } from '@/hooks/useTooltipVisibility'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'
import { Tooltip } from '@/ui/atoms/tool-tip'

import { HomepageGalleryTooltip } from './HomepageGalleryTooltip'
import { HomepageGalleryLinksContainer, TooltipButton } from './styled'

export type HomepageGalleryHeaderProps = {
  /**
   * Track event for the "Your ad here" link
   */
  handleYourAdHereTracking?: () => void
  /**
   * User's current location Id
   */
  locationId: number
  /**
   * Current location name as fallback if SEO URL query fails
   */
  locationName?: string | null
  /**
   * Get seo URL function to get the "see all" link
   */
  getSeoUrl: (categoryId: number, index?: number) => string
}

export const HomepageGalleryHeader: FC<HomepageGalleryHeaderProps> = ({
  locationName,
  locationId,
  handleYourAdHereTracking,
  getSeoUrl,
}) => {
  const { spacing, colors } = useTheme()
  const { t } = useTranslation('home')
  const {
    handleDismissTooltip,
    handleTooltipMouseEnter,
    handleTooltipMouseLeave,
    handleViewTooltip,
    isTooltipVisible,
    tooltipRef,
  } = useTooltipVisibility()

  const fallbackSeeAllUrl = t('see_all.link', {
    seoLocation: `${locationName}`.toLowerCase().replace(' ', '-'),
    locationId,
  })
  const seeAllHref = getSeoUrl(ALL_CATEGORIES_ID_NUM) || fallbackSeeAllUrl

  return (
    <Spacing mBottom={spacing.defaultSmall} data-testid="hp-gallery-header">
      <GalleryHeaderWrapper justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <HeadlineText as="h2" color={colors.grey.primary} size="medium" weight="regular">
            {t('gallery.title')}
          </HeadlineText>

          <Tooltip
            id="tooltip-homepage-gallery"
            isVisible={isTooltipVisible}
            onClose={handleDismissTooltip}
            onMouseEnter={handleTooltipMouseEnter}
            onMouseLeave={handleTooltipMouseLeave}
            orientation="top"
            tooltipId="hp-gallery-tooltip"
            tooltipText={
              <div ref={tooltipRef}>
                <HomepageGalleryTooltip />
              </div>
            }
            variant="light"
          >
            <TooltipButton
              role="tooltip"
              aria-labelledby="hp-gallery-tooltip-title"
              data-testid="hp-gallery-tooltip-button"
              onClick={handleViewTooltip}
              onMouseEnter={handleTooltipMouseEnter}
              onMouseLeave={handleTooltipMouseLeave}
            >
              <InfoTooltip aria-hidden />
            </TooltipButton>
          </Tooltip>
        </Flex>

        <HomepageGalleryLinksContainer gap={spacing.default}>
          <LinkCustom
            href={t('gallery.your_ad_here.link')}
            onClick={handleYourAdHereTracking}
            size="small"
            weight="regular"
          >
            {t('gallery.your_ad_here.label')}
          </LinkCustom>

          <LinkCustom href={seeAllHref} weight="regular" size="small" variant="secondary">
            {t('gallery.see_all.label')}
          </LinkCustom>
        </HomepageGalleryLinksContainer>
      </GalleryHeaderWrapper>
    </Spacing>
  )
}
