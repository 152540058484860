import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { Flex } from '@/ui/atoms/flex'
import { Skeleton } from '@/ui/atoms/skeleton'

import { LoadingGalleryContainer } from './styled'

export type LoadingGalleryProps = {
  /**
   * Should show loading skeleton for title above the gallery
   */
  hasLoadingTitle?: boolean
  /**
   * Defines how many items in the gallery loading state
   * @default 6
   */
  itemsCount?: number
  /**
   * Defines if legacy large breakpoint should be considered for the items count
   * This will help be backwards compatible while migrating legacy pages
   */
  useLegacyLgDesktopBreakpoint?: boolean
}

export const LoadingGallery: FC<LoadingGalleryProps> = ({
  hasLoadingTitle,
  itemsCount = 6,
  useLegacyLgDesktopBreakpoint,
}) => {
  const { spacing } = useTheme()

  return (
    <LoadingGalleryContainer
      flexDirection="column"
      gap={spacing.defaultSmall}
      useLegacyLgDesktopBreakpoint={useLegacyLgDesktopBreakpoint}
    >
      {hasLoadingTitle && (
        <Skeleton count={1} data-testid="loading-gallery-title" variant="inline" width="25rem" />
      )}

      <Flex gap={spacing.default} data-testid="loading-gallery-slides-list">
        <Skeleton count={itemsCount} width="16rem" height="20.2rem" variant="rectangle" />
      </Flex>
    </LoadingGalleryContainer>
  )
}
