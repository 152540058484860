import { theme } from '@kijiji/theme'
import styled, { type DefaultTheme } from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'

import { type FavouriteButtonColorScheme, type FavouriteButtonSize } from './FavouriteButton'

type ListingFavouriteButtonProps = {
  colorScheme: FavouriteButtonColorScheme
  size: FavouriteButtonSize
}

const getListingFavouriteButtonColor = (
  colorScheme: FavouriteButtonColorScheme,
  theme: DefaultTheme
) => {
  const colorSchemesConfig: Record<
    FavouriteButtonColorScheme,
    {
      pressed: string
      unpressed: string
    }
  > = {
    purple: {
      pressed: theme.colors.purple.primary,
      unpressed: theme.colors.purple.primary,
    },
    grey: {
      pressed: theme.colors.purple.primary,
      unpressed: theme.colors.grey.light1,
    },
  }

  return `
    color: ${colorSchemesConfig[colorScheme].unpressed};

    &[aria-pressed="true"] > svg {
      color: ${colorSchemesConfig[colorScheme].pressed};
    }
  `
}

const iconSizes: Record<FavouriteButtonSize, string> = {
  large: theme.spacing.large,
  xLarge: theme.spacing.xLarge,
}

export const ListingFavouriteButton = styled.button<ListingFavouriteButtonProps>(
  ({ theme, colorScheme, size }) => `
  z-index: ${zIndexRegistry.CARD_ELEMENTS};
  transition: transform .9s;

  ${getListingFavouriteButtonColor(colorScheme, theme)}
  
  & > svg {
    height: ${iconSizes[size]};
    width: ${iconSizes[size]};
  }

  &:hover {
    transform: scale(1.2);
  }
`
)
