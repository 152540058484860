import { CATEGORIES } from '@kijiji/category'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { GalleryCard } from '@/components/shared/gallery/GalleryCard'
import { type RouteLocale, ROUTE_LOCALE } from '@/domain/locale'
import { type BrandedTileData } from '@/types/customCampaigns'
import { Flex } from '@/ui/atoms/flex'
import kijijiAutosImgEn from '~/public/next-assets/images/homepage/kijiji-autos-en.webp'
import kijijiAutosImgFr from '~/public/next-assets/images/homepage/kijiji-autos-fr.webp'

import { BrandedTile } from './BrandedTile'
import { handlePopularCategoryTracking } from './domain'
import { PopularCategoriesHeader } from './PopularCategoriesHeader'
import { popularOnAutosStaticData } from './popularCategoriesStaticData'

export type PopularOnAutosProps = {
  /**
   * Branded tiles
   */
  campaign?: BrandedTileData
  /**
   * Defines if user is on a region location
   */
  isRegionLocation: boolean
  /**
   * Defines the location name user is currently in
   */
  locationName?: string | null
  /**
   * Gets the SEO url based on a categoryId
   */
  getSeoUrl: (categoryId: number, index?: number) => string
  /**
   * User's locale
   */
  routeLocale: RouteLocale
}

export const PopularOnAutos: FC<PopularOnAutosProps> = ({
  campaign,
  getSeoUrl,
  isRegionLocation,
  locationName,
  routeLocale,
}) => {
  const { t } = useTranslation('home')
  const { spacing } = useTheme()
  const categorySectionId = CATEGORIES.CARS_AND_VEHICLES_CATEGORY_ID
  const hasBrandedTile = campaign && campaign.hpCategory === categorySectionId
  const maxCardWidth = [`calc(calc(100% / 4) - 1.2rem)`, `calc(calc(100% / 2) - 1.2rem)`]

  return (
    <section data-testid="homepage-popular-autos">
      <PopularCategoriesHeader
        browseAllCategoryId={CATEGORIES.CARS_AND_VEHICLES_CATEGORY_ID}
        getSeoUrl={getSeoUrl}
        isRegionLocation={isRegionLocation}
        locationName={locationName}
        sectionKey="autos"
      />

      <Flex flexWrap="wrap" gap={spacing.default}>
        {/* MoVe Card */}
        <GalleryCard
          centralizedTitle={t('popular_autos_move.card_label')}
          hideCentralizedTitle
          href={t('popular_autos_move.href')}
          image={{
            alt: t('popular_autos_move.alt_text'),
            position: 'left',
            src: routeLocale === ROUTE_LOCALE.en ? kijijiAutosImgEn : kijijiAutosImgFr,
          }}
          index={0}
          isOnStaticGallery
          maxWidth={maxCardWidth}
          onClick={() => handlePopularCategoryTracking(0, true)}
          useLegacyLgDesktopBreakpoint
        />

        {/* Branded Tile */}
        {hasBrandedTile && <BrandedTile campaign={campaign} maxWidth={maxCardWidth} />}

        {popularOnAutosStaticData.map((item, index) => {
          const seoUrl = getSeoUrl(item.id, item.index)
          const position = index + 1

          if (hasBrandedTile && popularOnAutosStaticData.length === position) {
            return null
          }

          return (
            <GalleryCard
              centralizedTitle={t(`popular_categories.autos.${item.translationKey}`)}
              href={seoUrl}
              image={{ alt: '', src: item.assetSrc }}
              index={position}
              isOnStaticGallery
              key={`hp-popular-autos-${position}`}
              maxWidth={maxCardWidth}
              onClick={() => handlePopularCategoryTracking(item.id)}
              useLegacyLgDesktopBreakpoint
            />
          )
        })}
      </Flex>
    </section>
  )
}
