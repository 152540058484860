import { ShowAtOrLargerHP } from '@/components/homepage/shared/ShowAtOrLargerHP'
import { LEGACY_HP_LG_BREAKPOINT } from '@/constants/pageSettings'
import { useBreakpointChange } from '@/hooks/useBreakpointChange'
import { HOMEPAGE_GPT_ID } from '@/lib/ads/ads-config/homepage/homepageAdSlots'
import { triggerManualAdRefresh } from '@/lib/ads/utils/triggerManualAdRefresh'

import { AdChoicesLink } from './AdChoicesLink'
import { StyledGptRightrailTop } from './styled'

export const GptRightrailTop = () => {
  useBreakpointChange([LEGACY_HP_LG_BREAKPOINT], () => {
    triggerManualAdRefresh(HOMEPAGE_GPT_ID.RIGHTRAIL_TOP)
  })

  return (
    <>
      <ShowAtOrLargerHP breakpoint={LEGACY_HP_LG_BREAKPOINT}>
        <AdChoicesLink />
      </ShowAtOrLargerHP>

      <StyledGptRightrailTop
        id={HOMEPAGE_GPT_ID.RIGHTRAIL_TOP}
        data-testid={HOMEPAGE_GPT_ID.RIGHTRAIL_TOP}
      />
    </>
  )
}
