import { type FC } from 'react'

import { isValidLocation } from '@/domain/location/isValidLocation'
import { ContentModuleId, useGetContentModuleQuery } from '@/generated/graphql-types'

import { BrowseHero } from '../../shared/cms-modules/browse-hero/BrowseHero'

type HeroCMSBanner = {
  locationId: number
}

export const HeroCMSBanner: FC<HeroCMSBanner> = ({ locationId }) => {
  const { data } = useGetContentModuleQuery({
    fetchPolicy: 'cache-first',
    skip: !isValidLocation(locationId),
    variables: {
      locationId,
      moduleId: ContentModuleId.WelcomePopularBanner,
    },
  })

  return <BrowseHero contentModule={data?.contentModule} />
}
