import { AdSource } from '@/generated/graphql-types'

export const getGaAdType = (adType: string): string | null => {
  const type = adType.toLowerCase()

  if (adType === AdSource.Cas) return `partner_${type}`
  if (adType === AdSource.Move || adType === AdSource.Organic) return type

  return null
}
