import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { type UserLocation } from '@/generated/graphql-types'
import { useBrandedTileCampaign } from '@/hooks/campaigns/useBrandedTileCampaign'
import { useLocale } from '@/hooks/useLocale'
import { GptLeaderBase } from '@/lib/ads/ad-slots/homepage/GptLeaderBase'
import { GptLeaderBottom } from '@/lib/ads/ad-slots/homepage/GptLeaderBottom'
import { Spacing } from '@/ui/atoms/spacing'

import { HomepageGallery } from '../homepage-gallery/HomepageGallery'
import { KijijiCentralGallery } from '../kijiji-central-gallery/KijijiCentralGallery'
import { PopularOnAutos } from '../popular-categories/PopularOnAutos'
import { PopularOnBuyAndSell } from '../popular-categories/PopularOnBuyAndSell'
import { PopularOnRealEstate } from '../popular-categories/PopularOnRealEstate'
import { RecentlyViewedAds } from '../recently-viewed-ads/RecentlyViewedAds'
import { RecommendedForYou } from '../recommended-for-you/RecommendedForYou'
import { SignInBanner } from '../sign-in-banner/SignInBanner'
import { HomepageSectionWrapper } from '../styled'

type FeedFallbackComponentsProps = {
  getSeoUrl: (categoryId: number, index?: number | undefined) => string
  location: UserLocation
  userAgent: string
}
export const FeedFallbackComponents: FC<FeedFallbackComponentsProps> = ({
  getSeoUrl,
  location,
  userAgent,
}) => {
  const { apiLocale, routeLocale } = useLocale()
  const { spacing } = useTheme()
  /**
   * The branded tile campaign is used on the popular categories section
   * it will be added to the first tile in the assigned category section
   * And will remove its last static item.
   *  */
  const { campaign } = useBrandedTileCampaign(location.id)

  return (
    <>
      <Spacing mTop={spacing.husky}>
        <RecommendedForYou />
      </Spacing>

      <HomepageGallery
        userAgent={userAgent}
        locationName={location.name[apiLocale]}
        locationId={location.id}
        getSeoUrl={getSeoUrl}
      />

      <HomepageSectionWrapper mTop={spacing.husky} mBottom={spacing.husky}>
        <PopularOnAutos
          campaign={campaign}
          getSeoUrl={getSeoUrl}
          isRegionLocation={location.isRegion}
          locationName={location.name[apiLocale]}
          routeLocale={routeLocale}
        />

        <GptLeaderBottom />
      </HomepageSectionWrapper>

      <RecentlyViewedAds />

      <PopularOnBuyAndSell
        campaign={campaign}
        getSeoUrl={getSeoUrl}
        isRegionLocation={location.isRegion}
        locationName={location.name[apiLocale]}
      />

      <HomepageSectionWrapper>
        <Spacing mTop={spacing.husky}>
          <SignInBanner />
        </Spacing>

        <GptLeaderBase />
      </HomepageSectionWrapper>

      <Spacing mTop={spacing.husky} mBottom={spacing.husky}>
        <PopularOnRealEstate
          campaign={campaign}
          getSeoUrl={getSeoUrl}
          isRegionLocation={location.isRegion}
          locationName={location.name[apiLocale]}
        />
      </Spacing>

      <KijijiCentralGallery />
    </>
  )
}
