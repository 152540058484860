import { CATEGORIES } from '@kijiji/category'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'

import { GalleryCard } from '@/components/shared/gallery/GalleryCard'
import { GalleryHeaderWrapper } from '@/components/shared/gallery/GalleryHeaderWrapper'
import { GalleryScrollableContainer } from '@/components/shared/gallery/GalleryScrollableContainer'
import { GalleryWrapper } from '@/components/shared/gallery/GalleryWrapper'
import { type BrandedTileData } from '@/types/customCampaigns'

import { BrandedTile } from './BrandedTile'
import { handlePopularCategoryTracking } from './domain'
import { PopularCategoriesHeader } from './PopularCategoriesHeader'
import { popularOnRealEstateStaticData } from './popularCategoriesStaticData'

export type PopularOnRealEstateProps = {
  /**
   * Branded tile
   */
  campaign?: BrandedTileData
  /**
   * Defines if user is on a region location
   */
  isRegionLocation: boolean
  /**
   * Defines the location name user is currently in
   */
  locationName?: string | null
  /**
   * Gets the SEO url based on a categoryId
   */
  getSeoUrl: (categoryId: number, index?: number) => string
}

export const PopularOnRealEstate: FC<PopularOnRealEstateProps> = ({
  campaign,
  getSeoUrl,
  isRegionLocation,
  locationName,
}) => {
  const { t } = useTranslation('home')
  const categorySectionId = CATEGORIES.REAL_ESTATE_CATEGORY_ID
  const hasBrandedTile = campaign && campaign.hpCategory === categorySectionId

  return (
    <GalleryWrapper data-testid="homepage-popular-real-estate">
      <GalleryHeaderWrapper>
        <PopularCategoriesHeader
          browseAllCategoryId={CATEGORIES.REAL_ESTATE_CATEGORY_ID}
          getSeoUrl={getSeoUrl}
          isRegionLocation={isRegionLocation}
          sectionKey="real_estate"
          locationName={locationName}
        />
      </GalleryHeaderWrapper>

      <GalleryScrollableContainer>
        {/* Branded Tile */}
        {hasBrandedTile && <BrandedTile campaign={campaign} />}

        {popularOnRealEstateStaticData.map((item, index) => {
          const seoUrl = getSeoUrl(item.id, item.index)
          const position = index + 1

          if (hasBrandedTile && popularOnRealEstateStaticData.length === position) {
            return null
          }

          return (
            <GalleryCard
              centralizedTitle={t(`popular_categories.real_estate.${item.translationKey}`)}
              href={seoUrl}
              image={{ alt: '', src: item.assetSrc }}
              index={index + 1}
              key={`hp-popular-real_estate-${index + 1}`}
              onClick={() => handlePopularCategoryTracking(item.id)}
              useLegacyLgDesktopBreakpoint
            />
          )
        })}
      </GalleryScrollableContainer>
    </GalleryWrapper>
  )
}
