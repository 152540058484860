import { CATEGORIES } from '@kijiji/category'
import { type StaticImageData } from 'next/image'

import { FEED_CATEGORY_ASSETS } from '@/constants/category'
import { type RouteLocale, ROUTE_LOCALE } from '@/domain/locale'

import { AUTO_LOAD_COUNT, HP_FEED_REQUEST_CHUNKS, ROWS_PER_LOAD } from '../useHomepageInfiniteFeed'
import { HOME_FEED_ITEMS_PER_ROW_DESKTOP } from './styled'

export const showHomepageGalleryAfterFeedItem = (position: number) => {
  /**
   * Should show the homepage gallery on the 3rd row of the gallery
   * There are 4 items per row on Desktop - This will append the gallery after the 12th item of the gallery
   * The same will happen on mobile - The gallery will show up after the 12th item of the gallery
   */

  return position === HOME_FEED_ITEMS_PER_ROW_DESKTOP * AUTO_LOAD_COUNT
}

export const showHomeFeedLoadMoreButton = (position: number) => {
  /**
   * It will show every 72 items loaded - every 18 rows
   */
  const multipleOf = HOME_FEED_ITEMS_PER_ROW_DESKTOP * ROWS_PER_LOAD
  return position % multipleOf === 0
}

export const showHomeFeedAdvertisement = (position: number) => {
  /**
   * Should show maximum of 10 advertisement
   * It will show on top of each "Load More" button
   *
   * It will show every 72 items loaded - every 18 rows
   */
  const adNumber = Math.floor(position / (HP_FEED_REQUEST_CHUNKS * AUTO_LOAD_COUNT))
  const shouldShowAd = showHomeFeedLoadMoreButton(position) && adNumber <= 10

  return { shouldShowAd, adNumber: shouldShowAd ? adNumber : 0 }
}

export const homepageFeedPopularCategoryImageSource = (
  categoryId: string,
  locale: RouteLocale
): StaticImageData => {
  /** Real estate is the only category that has a FR version */
  if (categoryId === CATEGORIES.REAL_ESTATE_CATEGORY_ID.toString() && locale === ROUTE_LOCALE.fr) {
    return FEED_CATEGORY_ASSETS[`${categoryId}fr`]
  }

  return FEED_CATEGORY_ASSETS[categoryId]
}
