import { createLocationIdLabel } from '@/lib/ads/components/gpt/searchGpt'
import { type GptTargeting } from '@/lib/ads/types/adConfig'

export const getLocationTargetingKeys = ({
  locationId,
}: {
  locationId: number
}): GptTargeting[] => {
  /** Location Keys */
  const locationKeys: GptTargeting[] = [createLocationIdLabel(locationId)]

  return locationKeys
}
