import { HideAtOrLargerHP } from '@/components/homepage/shared/ShowAtOrLargerHP'
import { LEGACY_HP_LG_BREAKPOINT } from '@/constants/pageSettings'
import { HOMEPAGE_GPT_ID } from '@/lib/ads/ads-config/homepage/homepageAdSlots'

import { AdChoicesLink } from './AdChoicesLink'
import { GptRightrailMarketingWrapper, StyledGptRightrailMarketing } from './styled'

export const GptRightrailMarketing = () => {
  return (
    <GptRightrailMarketingWrapper>
      <HideAtOrLargerHP breakpoint={LEGACY_HP_LG_BREAKPOINT}>
        <AdChoicesLink />
      </HideAtOrLargerHP>

      <StyledGptRightrailMarketing
        id={HOMEPAGE_GPT_ID.RIGHTRAIL_MARKETING}
        data-testid={HOMEPAGE_GPT_ID.RIGHTRAIL_MARKETING}
      />
    </GptRightrailMarketingWrapper>
  )
}
