import { type UserStatus } from 'next-auth'

import { PAGE_TYPE } from '@/constants/pageTypes'
import { type RouteLocale } from '@/hooks/useLocale'
import { PTG } from '@/lib/ads/ads-config/homepage/homepageAdSlots'
import { createHostnameLabel } from '@/lib/ads/components/gpt/configGpt'
import { getGptDeviceType } from '@/lib/ads/components/gpt/deviceGpt'
import { createUserLoggedInLabelKj } from '@/lib/ads/components/gpt/userGpt'
import { GPT_KEYS } from '@/lib/ads/constants/gpt'
import { type GptTargeting } from '@/lib/ads/types/adConfig'
import { getGenericTargetingKeys } from '@/lib/ads/utils/targeting/getGenericTargetingKeys'
import { getIntowowTargetingKeys } from '@/lib/ads/utils/targeting/getIntowowTargetingKeys'
import { getLocationTargetingKeys } from '@/lib/ads/utils/targeting/getLocationTargetingKeys'
import { getUserTargetingKeys } from '@/lib/ads/utils/targeting/getUserTargetingKeys'
import { type LocationPath } from '@/types/location'

/**
 * Formatting logic ported from appendLocationsToSlot in BOX
 * 1. convert to lowercase
 * 2. remove whitespace, periods, single quotes, and parentheses
 * 3. replace slashes (/) with hyphens (-)
 * @param {string} locationName , eg Toronto (GTA) or Banff / Canmore
 * @returns {string}, eg torontogta or banff-canmore
 */
const processLocationName = (locationName: string) => {
  return locationName
    .toLowerCase()
    .replace(/[\s.'()]/g, '')
    .replace(/\//g, '-')
}

/**
 * The locationPaths array contains the location hierarchy,
 * i.e. country -> province/region -> city -> sub-city
 * it will always contain at least the country and province/region unless the locationId is 0
 * @param {number} locationId
 * @param {LocationPath[]}locationPaths
 * @returns {GptTargeting[]} eg [{ key: 'lc', value: '1700234' }, { key: 'province', value: 'ontario' }]
 */
const generateLocationValues = (locationPaths: LocationPath[]) => {
  const locationTargetingValues = locationPaths.reduce(
    (acc: GptTargeting[], currLocationPath, index) => {
      if (index === 0) return acc // we don't need the country

      // get province/region, eg Onrario
      if (index === 1) {
        const processedLocationName = processLocationName(currLocationPath.name?.en_CA || '')
        return [...acc, { key: GPT_KEYS.PROVINCE_TARGETING_LABEL, value: processedLocationName }]
      }

      // get city name if it exists, eg Toronto (GTA)
      if (index === 2) {
        const processedLocationName = processLocationName(currLocationPath.name?.en_CA || '')
        return [...acc, { key: GPT_KEYS.CITY_TARGETING_LABEL, value: processedLocationName }]
      }

      // get sub-city name if it exists, eg City of Toronto
      if (index === 3) {
        const processedLocationName = processLocationName(currLocationPath.name?.en_CA || '')
        return [...acc, { key: GPT_KEYS.SUB_CITY_TARGETING_LABEL, value: processedLocationName }]
      }

      return acc
    },
    []
  )

  return locationTargetingValues
}

export const generateGptTargetingValues = (
  userStatus: UserStatus,
  routeLocale: RouteLocale,
  locationId: number,
  locationPaths: LocationPath[] | undefined,
  hasUserOptedOutTargeting: boolean
) => {
  /** Generic Values */
  const genericTargeting = getGenericTargetingKeys({
    hasUserOptedOutTargeting,
    pageType: PAGE_TYPE.HOMEPAGE,
    ptgValues: PTG,
  })

  const userTargeting = getUserTargetingKeys({ userStatus, routeLocale })
  const locationTargeting = getLocationTargetingKeys({ locationId })
  const intowowTargeting = getIntowowTargetingKeys()

  /** Deprecated values - Won't return by the global functions */
  const deprecatedTargeting: GptTargeting[] = [
    /** Generic */
    { key: GPT_KEYS.DEVICE_LABEL, value: getGptDeviceType() },
    { key: GPT_KEYS.HOSTNAME_LABEL, value: createHostnameLabel().value },
    /** User */
    createUserLoggedInLabelKj(userStatus),
    /** Location */
    ...generateLocationValues(locationPaths ?? []),
  ]

  const gptTargetingValues = [
    ...genericTargeting,
    ...userTargeting,
    ...locationTargeting,
    ...intowowTargeting,

    /** TODO: Remove deprecated values as a follow up - verify with adas team */
    ...deprecatedTargeting,
  ]

  return gptTargetingValues
}
