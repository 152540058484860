import FavouriteOutlineIcon from '@kijiji/icons/src/icons/FavouriteOutline'
import FavouriteSolidIcon from '@kijiji/icons/src/icons/FavouriteSolid'
import { type ThemeProps } from '@kijiji/theme'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import qs from 'query-string'
import { type FC, type MouseEvent, useState } from 'react'

import {
  type GetUserFavouritesListQuery,
  AuthModalVariant,
  useGetUserFavouritesListQuery,
} from '@/generated'
import { useFavouriteListingUpdate } from '@/hooks/useFavouriteListingUpdate'
import { useSignInModal } from '@/hooks/useSignInModal'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { trackEvent } from '@/lib/ga/utils/tracking'

import { ListingFavouriteButton } from './styled'

type AllowedColorSchemes = 'grey' | 'purple'
export type FavouriteButtonSize = 'large' | 'xLarge'
export type FavouriteButtonColorScheme = Extract<keyof ThemeProps['colors'], AllowedColorSchemes>
export type FavouriteButtonProps = {
  listingId: string
  colorScheme?: FavouriteButtonColorScheme
  size?: FavouriteButtonSize
}

const FavouriteButton: FC<FavouriteButtonProps> = ({
  listingId,
  colorScheme = 'grey',
  size = 'large',
}) => {
  const [isFavourite, setIsFavourite] = useState(false)

  const { t } = useTranslation('srp')

  const { data: userData } = useSession()
  const { openSignInModal } = useSignInModal()
  const { updateFavourite, updateLoading } = useFavouriteListingUpdate()
  const { asPath } = useRouter()

  const { refetch } = useGetUserFavouritesListQuery({
    ssr: false,
    skip: !userData?.user.sub,
    fetchPolicy: 'cache-first',
    onCompleted: (data: GetUserFavouritesListQuery) => {
      if (data.favouriteListingsIds && data.favouriteListingsIds.includes(listingId)) {
        setIsFavourite(true)
      }
    },
  })

  const handleToggleFavourite = (event: MouseEvent) => {
    /**
     * Prevents a link redirect to be triggered when used inside of a listing card
     * */
    event.preventDefault()
    event.stopPropagation()

    if (updateLoading) {
      return
    }

    if (!userData?.user) {
      const { url, query: queryParams } = qs.parseUrl(asPath)
      queryParams.listingId = listingId
      const callbackUrl = qs.stringifyUrl({ url, query: queryParams })

      openSignInModal({ modalVariant: AuthModalVariant.LoginToFavourite, callbackUrl })

      return
    }

    setIsFavourite(!isFavourite)

    updateFavourite(isFavourite, {
      variables: { listingId: listingId.toString() },
      onError: (error) => {
        // Catches off case a listing is already favourited resulting in an error.
        // This is a good indication to refetch the list of favourite ids
        if (error.message.includes('already favourited')) {
          refetch()
          return
        }
        setIsFavourite(isFavourite)
      },
      onCompleted: () => {
        trackEvent({
          action: (isFavourite && GA_EVENT.WatchlistRemove) || GA_EVENT.WatchlistAdd,
          label: `adId=${listingId}`,
        })
      },
      onQueryUpdated: () => false, // the trick to preventing a refetch of main query after mutation
    })
  }

  return (
    <ListingFavouriteButton
      title={`${t('listing.favourite.button.click_to_favourite')}`}
      aria-label={
        isFavourite
          ? `${t('listing.favourite.button.label_remove')}`
          : `${t('listing.favourite.button.label_add')}`
      }
      aria-pressed={isFavourite}
      data-testid="listing-favourite-button"
      onClick={handleToggleFavourite}
      type="button"
      colorScheme={colorScheme}
      size={size}
    >
      {isFavourite ? <FavouriteSolidIcon aria-hidden /> : <FavouriteOutlineIcon aria-hidden />}
    </ListingFavouriteButton>
  )
}

export default FavouriteButton
