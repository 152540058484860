import styled from 'styled-components'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '@/components/homepage/styled'
import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

import { type LoadingGalleryProps } from './LoadingGallery'

type LoadingGalleryContainerProps = Pick<LoadingGalleryProps, 'useLegacyLgDesktopBreakpoint'>
export const LoadingGalleryContainer = styled(Flex)<LoadingGalleryContainerProps>(
  ({ theme, useLegacyLgDesktopBreakpoint }) => `
  overflow: hidden;
  margin: 0.5rem 0 0 ${theme.spacing.husky};

  ${useLegacyLgDesktopBreakpoint ? MEDIA_QUERY_HP_LARGE_DESKTOP : MEDIA_QUERIES(theme).xLarge} {
    margin:0;
  }  
`
)

export const LoadingVerticalGalleryContainer = styled.div(
  ({ theme }) => `
  display: grid;
  gap: 1.6rem;
  grid-template-columns: repeat(2, 1fr);

  ${MEDIA_QUERIES(theme).medium}{
    grid-template-columns: repeat(4, 1fr);
  }
`
)

export const LoadingGalleryCardContainer = styled.div(
  ({ theme }) => `
  background: ${theme.colors.white};
`
)

export const LoadingGalleryCardBody = styled.div(
  ({ theme }) => `
  border-radius: ${theme.borderRadius.small};
  box-shadow: 0 .1rem .1rem 0 rgba(0,0,0,.1);
  padding: ${theme.spacing.defaultSmall} ${theme.spacing.defaultSmall} ${theme.spacing.default};
`
)
