import {
  type BrandedCategoryItem,
  type FeedItem,
  type FeedResults,
  type GalleryItem,
  type Location,
  type MenuItem,
  type PopularCategoryItem,
  type RecentlyViewedListings,
} from '@/generated/graphql-types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isGalleryItemsType = (items?: any): items is GalleryItem[] =>
  (items as GalleryItem[])?.[0]?.__typename === 'GalleryItem'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFeedResultsType = (results?: any): results is FeedResults[] =>
  (results as FeedResults[])?.[0]?.__typename === 'BrandedCategoryItem' ||
  (results as FeedResults[])?.[0]?.__typename === 'FeedItem' ||
  (results as FeedResults[])?.[0]?.__typename === 'PopularCategoryItem'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFeedItem = (result: any): result is FeedItem =>
  (result as FeedItem)?.__typename === 'FeedItem'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isBrandedCategoryItem = (result: any): result is BrandedCategoryItem =>
  (result as BrandedCategoryItem)?.__typename === 'BrandedCategoryItem'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPopularCategoryItem = (result: any): result is PopularCategoryItem =>
  (result as PopularCategoryItem)?.__typename === 'PopularCategoryItem'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isMenuItem = (menu?: any): menu is MenuItem =>
  (menu as MenuItem)?.__typename === 'MenuItem'

export const isMenuitemArray = (menu?: unknown[]): menu is MenuItem[] =>
  !(menu as MenuItem[])?.find((item) => item.__typename !== 'MenuItem')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isLocationPath = (location?: any): location is Location[] =>
  (location as Location[])?.[0]?.__typename === 'Location'

export const isRecentlyViewedListings = (
  recentlyViewedListings?: unknown
): recentlyViewedListings is RecentlyViewedListings[] =>
  (recentlyViewedListings as RecentlyViewedListings[])?.[0]?.__typename === 'RecentlyViewedListings'
