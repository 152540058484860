import { AdvertisingSlot } from 'react-advertising'
import styled from 'styled-components'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '@/components/homepage/styled'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const AD_CHOICES_ICON_SIZE = 13

/**
 * Can be either 300x250 or 300x400, but predominantly 300x250
 * so set a min-height of 250px to reduce layout shift
 */
export const StyledGptRightrailMarketing = styled(AdvertisingSlot)(
  ({ theme }) => `
  overflow: hidden;
  min-height: 250px;

  ${MEDIA_QUERIES(theme).medium} {
    margin-left: ${theme.spacing.husky};
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    margin-left: 0;
    margin-top: 2rem;
  }
`
)

export const GptRightrailMarketingWrapper = styled.div`
  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    position: sticky;
    top: 0;
  }
`

/**
 * Can be either 300x250 or 300x600, but predominantly 300x250
 * so set a min-height of 250px to reduce layout shift
 */
export const StyledGptRightrailTop = styled(AdvertisingSlot)(
  ({ theme }) => `
  display: none;
  overflow: hidden;
  
  margin-bottom: ${theme.spacing.default};

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    display: block;
    margin-left: 0;
    min-height: 250px;
  }
`
)

export const StyledGptRightrailBottom = styled(AdvertisingSlot)(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  // will only apply style when the ad loads
  &[data-google-query-id] iframe{
    margin: 2rem auto;
  }

  ${MEDIA_QUERIES(theme).medium} {
    // will only apply style when the ad loads
    &[data-google-query-id] iframe {
      margin: 3rem auto;
    }
  }
`
)

export const StyledGptStickyHP = styled(AdvertisingSlot)(
  ({ theme }) => `
  position: fixed;
  bottom: 0;
  z-index: ${zIndexRegistry.MWEB_STICKY_AD};
  display: flex;
  justify-content: center;
  width: 100%;
  
  // will only apply style when the ad loads
  &[data-google-query-id] { 
    background-color: rgba(255, 255, 255, 0.75); 
    border-top: .1rem ${theme.colors.grey.light3} solid;
    padding-top: ${theme.spacing.default};
    padding-bottom: ${theme.spacing.default};
  }

  ${MEDIA_QUERIES(theme).medium} {
    display: none;
  }
`
)

/**
 * We only serve 728x90
 * so set a min-height of 90px to prevent layout shift
 */
export const StyledGptLeaderBase = styled(AdvertisingSlot)(
  ({ theme }) => `
  display: none;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: ${theme.spacing.husky};
  margin-top: ${theme.spacing.husky};
  min-height: 90px;

  ${MEDIA_QUERIES(theme).medium} {
    display: flex;
  }
`
)

export const StyledGptLeaderBaseHomeFeed = styled(AdvertisingSlot)(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: ${theme.spacing.husky};
  margin-top: ${theme.spacing.husky};
`
)

export const StyledAdChoicesCustomLink = styled(LinkCustom)(
  ({ theme }) => `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${theme.spacing.mini};
  margin-bottom: ${theme.spacing.defaultSmall};
`
)

export const AdChoicesIconContainer = styled.div`
  height: ${AD_CHOICES_ICON_SIZE}px;
  width: ${AD_CHOICES_ICON_SIZE}px;
`
