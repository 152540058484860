import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { type FC, useRef } from 'react'
import { useTheme } from 'styled-components'

import { useClickOutside } from '@/hooks/useClickOutside'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

import { MegaMenuSubCategoriesList } from './MegaMenuSubCategoriesList'
import {
  MegaMenuModalContainer,
  MegaMenuModalHeader,
  MegaMenuModalHeaderBackButton,
  MegaMenuModalPanel,
  SubCategoryMenu,
} from './styled'
import { type ActiveMegaMenuReturn } from './useActiveMegaMenu'

const CloseButton = dynamic(
  () => import('@/ui/molecules/modal/ModalCloseButton').then((mod) => mod.ModalCloseButton),
  {
    ssr: false,
  }
)
const RightArrow = dynamic(() => import('@kijiji/icons/src/icons/Chevron'), { ssr: false })

type MegaMenuModalProps = Pick<
  ActiveMegaMenuReturn,
  | 'activeL2Items'
  | 'activeL3Items'
  | 'brandedCategoryCampaign'
  | 'closeMenu'
  | 'isCollapsedMenuView'
  | 'isKeyboardNavigation'
  | 'openMenu'
  | 'selectedL1Item'
  | 'selectedL2Item'
>

export const MegaMenuModal: FC<MegaMenuModalProps> = ({
  activeL2Items,
  activeL3Items,
  brandedCategoryCampaign,
  closeMenu,
  isCollapsedMenuView,
  isKeyboardNavigation,
  openMenu,
  selectedL1Item,
  selectedL2Item,
}) => {
  const { t } = useTranslation(['common', 'home'])
  const { colors, spacing } = useTheme()
  const modalRef = useRef(null)

  const handleCloseModal = () => {
    closeMenu(2)
  }

  const handleCloseL3 = () => {
    closeMenu(3)
  }

  useClickOutside(modalRef, handleCloseModal)

  const isL3Menu = !!selectedL2Item && !!activeL3Items?.length

  return (
    <MegaMenuModalContainer
      id="mega-menu-modal"
      isOpen={isCollapsedMenuView && !!selectedL1Item && !!activeL2Items.length}
      label={t('home:mega_menu.nav.label')}
      onCancel={handleCloseModal}
    >
      <MegaMenuModalPanel isL3Menu={isL3Menu} ref={modalRef}>
        <CloseButton
          onClick={handleCloseModal}
          data-testid="ModalCloseButton"
          aria-label={t('common:modals.close.button.label')}
        />
        {!!selectedL1Item && !isL3Menu ? (
          <>
            <Spacing mTop={spacing.default} mBottom="1rem">
              <HeadlineText as="h3" size="large" color={colors.purple.primary}>
                {selectedL1Item?.categoryName}
              </HeadlineText>
            </Spacing>

            <SubCategoryMenu
              aria-labelledby={`cat-menu-item-${selectedL1Item?.id}`}
              id={`cat-menu-group-${selectedL1Item?.id}`}
              role="menu"
            >
              <MegaMenuSubCategoriesList
                activeL2Items={activeL2Items}
                activeL3Items={activeL3Items}
                brandedCategoryCampaign={brandedCategoryCampaign}
                categoryLevel={2}
                isCollapsedMenuView
                isKeyboardNavigation={isKeyboardNavigation}
                openMenu={openMenu}
                parentCategoryId={selectedL1Item.id}
                parentCategoryName={selectedL1Item.categoryName}
                parentSeoUrl={selectedL1Item.seoUrl}
              />
            </SubCategoryMenu>
          </>
        ) : null}

        {isL3Menu ? (
          <>
            <MegaMenuModalHeader>
              <MegaMenuModalHeaderBackButton onClick={handleCloseL3}>
                <span>
                  <RightArrow aria-hidden />
                </span>
                {t('home:mega_menu.modal.back_to', { categoryName: selectedL1Item?.categoryName })}
              </MegaMenuModalHeaderBackButton>
            </MegaMenuModalHeader>

            <Spacing p={spacing.default}>
              <Spacing mTop={spacing.default} mBottom="1rem">
                <HeadlineText as="h3" size="large" color={colors.purple.primary}>
                  {selectedL2Item.categoryName}
                </HeadlineText>
              </Spacing>

              <SubCategoryMenu
                aria-labelledby={`cat-menu-item-${selectedL2Item.id}`}
                id={`cat-menu-group-${selectedL2Item.id}`}
                role="menu"
              >
                <MegaMenuSubCategoriesList
                  activeL2Items={activeL2Items}
                  activeL3Items={activeL3Items}
                  brandedCategoryCampaign={brandedCategoryCampaign}
                  categoryLevel={3}
                  isCollapsedMenuView
                  isKeyboardNavigation={isKeyboardNavigation}
                  openMenu={openMenu}
                  parentCategoryId={selectedL2Item.id}
                  parentCategoryName={selectedL2Item.categoryName}
                  parentSeoUrl={selectedL2Item.seoUrl}
                />
              </SubCategoryMenu>
            </Spacing>
          </>
        ) : null}
      </MegaMenuModalPanel>
    </MegaMenuModalContainer>
  )
}
