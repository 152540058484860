import styled from 'styled-components'

import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '../styled'

/**
 * We don't need to use <Button /> component here because it is just a wrapper for the icon.
 * This won't have any styles. It will be invisible to the user as they will click on the icon.
 */
// eslint-disable-next-line @kijiji/prefer-button-component
export const TooltipButton = styled.button(
  ({ theme }) => `
  cursor: help;
  height: 2rem;
  margin-left: ${theme.spacing.defaultSmall};
  width: 2rem;

  & svg {
    height: 2.2rem;
    margin-top: ${theme.spacing.mini};
    width: 2.2rem;
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    margin-left: 0;
  }
`
)

export const HomepageGalleryLinksContainer = styled(Flex)(
  ({ theme }) => `
  margin-right: ${theme.spacing.default};
  
  & a:first-of-type {
    display: none;
    color: ${theme.colors.grey.light1};
  }

  ${MEDIA_QUERIES(theme).medium}{
    margin-right: 0;
    
    & a:first-of-type {
      display: block;
    }
  }
`
)
