import Image from 'next/image'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { type GetContentModuleQuery, type Tracking } from '@/generated/graphql-types'
import { trackEvent } from '@/lib/ga'
import { type GaEvent } from '@/lib/ga/constants/gaEvent'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { MEDIA_QUERIES_SHORT } from '@/ui/constants/mediaQueries'

import { textFragmentAssembler } from '../textFragmentAssembler'
import {
  BrowseHeroContainer,
  BrowseHeroSection,
  BrowseHeroTilesContainer,
  BrowseHeroTitleContainer,
  TileLink,
  TileLinkContainer,
  TileModule,
} from './styled'

export type BrowseHeroProps = {
  contentModule?: GetContentModuleQuery['contentModule']
}

export const BrowseHero: FC<BrowseHeroProps> = ({ contentModule }) => {
  const { tiles = [], title } = contentModule || {}
  const theme = useTheme()

  /**
   * Tracking definition for each tile will also come from the content module
   * If no tracking is sent, we won't send an event
   * */
  const handleTileTracking = (tracking?: Tracking | null) => {
    if (!tracking) return

    trackEvent({
      /**
       * Typecasting here is necessary since this value is not controlled by us
       * */
      action: tracking.action as GaEvent,
      label: tracking.label,
    })
  }

  return (
    <BrowseHeroSection data-testid="browse-hero-module">
      <BrowseHeroContainer top="0">
        <BrowseHeroTitleContainer>
          <HeadlineText
            as="h1"
            color={theme.colors.white}
            data-testid="browse-hero-title"
            size="large"
            weight="medium"
          >
            {textFragmentAssembler(title)}
          </HeadlineText>
        </BrowseHeroTitleContainer>

        <BrowseHeroTilesContainer>
          {tiles?.slice(0, 3).map((tile, idx) => {
            if (!tile) return null

            return (
              <TileModule key={`tile-${tile.title}-${tile.link.url}-${idx}`}>
                {tile.background?.imageUrl ? (
                  <Image
                    alt=""
                    fill
                    priority
                    sizes={`${MEDIA_QUERIES_SHORT(theme).medium} 402px, 318px`}
                    src={tile.background.imageUrl}
                  />
                ) : null}

                <TileLink
                  hasUnderline
                  href={tile.link.url}
                  onClick={() => handleTileTracking(tile.tracking)}
                  size="medium"
                  target={tile.link.isNewTab ? '_blank' : '_self'}
                  variant="secondary"
                  weight="bold"
                >
                  <TileLinkContainer>{tile.title}</TileLinkContainer>
                </TileLink>
              </TileModule>
            )
          })}
        </BrowseHeroTilesContainer>
      </BrowseHeroContainer>
    </BrowseHeroSection>
  )
}
