import { type RefObject, useRef, useState } from 'react'

import { useClickOutside } from './useClickOutside'

const HOVER_DELAY_MS = 200

type TooltipVisibilityReturn = {
  /**
   * This ref should be placed on the wrapper of the tooltip container
   * This will control the outside click to instantly close the tooltip
   */
  tooltipRef: RefObject<HTMLDivElement>

  /**
   * Controls tooltip visibility
   * Should be passed as prop to the tooltip component
   */
  isTooltipVisible: boolean

  /**
   * Controls the visibility of the tooltip on mouseLeave
   */
  handleTooltipMouseLeave: () => void
  /**
   * Controls the visibility of the tooltip on mouseEnter
   */
  handleTooltipMouseEnter: () => void
  /**
   * Instantly dismissed the tooltip
   */
  handleDismissTooltip: () => void
  /**
   * Instantly displays the tooltip
   */
  handleViewTooltip: () => void
}

export const useTooltipVisibility = (): TooltipVisibilityReturn => {
  /**
   * It will manually maintain the tooltip visible if the user clicks on the icon
   * OR if the user hover over the icon
   *
   * Eventually we could move this functionality to the component library and re-work the tooltip
   * */
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false)
  const tooltipTimeout = useRef<NodeJS.Timeout | null>(null)

  const tooltipRef = useRef<HTMLDivElement>(null)
  useClickOutside(tooltipRef, () => {
    if (isTooltipVisible) handleDismissTooltip()
  })

  const handleDismissTooltip = () => {
    setIsTooltipVisible(false)
  }

  const handleViewTooltip = () => {
    setIsTooltipVisible(true)
  }

  const handleClearTooltipTimeout = () => {
    tooltipTimeout.current && clearTimeout(tooltipTimeout.current)
  }

  const handleTooltipMouseEnter = () => {
    handleClearTooltipTimeout()
    tooltipTimeout.current = setTimeout(handleViewTooltip, HOVER_DELAY_MS)
  }

  const handleTooltipMouseLeave = () => {
    handleClearTooltipTimeout()
    tooltipTimeout.current = setTimeout(handleDismissTooltip, HOVER_DELAY_MS)
  }

  return {
    handleDismissTooltip,
    handleTooltipMouseEnter,
    handleTooltipMouseLeave,
    handleViewTooltip,
    isTooltipVisible,
    tooltipRef,
  }
}
