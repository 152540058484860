import CloseIcon from '@kijiji/icons/src/icons/Close'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'

import { type NotificationsQuery, useNotificationsQuery } from '@/generated'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'

import { ImageYAMS } from '../../shared/image/ImageYAMS'
import { Body, DismissButton, PriceDropContainer, Title, ViewNow } from './styled'

/**
 * Filters and returns the most recent unread price drop notification
 *
 * @param data - The NotificationsQuery data or undefined
 * @returns The most recent unread price drop notification or null
 */
export const getPriceDroptNotification = (data: NotificationsQuery | undefined) => {
  if (!data) {
    return null
  }
  // sorts and filter notifcations by date and type - leaving only unread price_drop type
  // could be refactored as a helper function for sorting notfications by type
  const unReadPricedropNotifications = data.notifications.notifications
    .filter(
      (notification): notification is NonNullable<typeof notification> =>
        notification !== null &&
        notification !== undefined &&
        notification?.type === 'price_drop' &&
        notification.read === false
    )
    .sort((a, b) => new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime())

  // return the first item in the array - the most recent
  return unReadPricedropNotifications[0]
}

/**
 * PriceDrop component to display price drop notifications to the user
 */
export const PriceDrop = () => {
  // get the user id
  const { data: userData } = useSession()
  const userId = parseInt(`${userData?.user.sub}`)
  // state used for making api calls and displaying the component
  const [isVisible, setIsVisible] = useState(true)
  const [hasDismissedPriceDrop, setHasDismissedPriceDrop] = useState(false)
  const [isClient, setIsClient] = useState(false)
  // copy
  const { t } = useTranslation('home')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(true)
      const dismissed = sessionStorage.getItem('hasDismissedPriceDrop') === 'true'
      setHasDismissedPriceDrop(dismissed)
    }
  }, [])

  // get the notifications
  const { data } = useNotificationsQuery({
    skip: !isClient || !userId || hasDismissedPriceDrop,
    ssr: false,
    variables: { userId },
    onCompleted: () => {
      // track things
    },
  })

  // now that we have the notifications check for a price drop notification
  const priceDropNotification = getPriceDroptNotification(data)

  // all the reasons NOT to show this thing
  if (!priceDropNotification || !isVisible || hasDismissedPriceDrop) {
    return null
  }

  // if they do have a pricedrop notification - lets show it!
  return (
    priceDropNotification && (
      <PriceDropContainer data-testid="price-drop">
        <ImageYAMS
          alt={priceDropNotification.title}
          data-testid="listing-card-image"
          src={priceDropNotification.pictureUrl}
          width={96}
          height={72}
          extension="WEBP"
        />
        <div>
          <Title>{priceDropNotification?.title}</Title>
          <Body>
            {priceDropNotification.body}
            <ViewNow
              href={priceDropNotification?.destinationUrl ?? '#'}
              onClick={() =>
                trackEvent({ action: GA_EVENT.SelectPromotion, label: 'promotion_name=pricedrop' })
              }
            >
              {t('home:price_drop.view_now')}
            </ViewNow>
          </Body>
        </div>
        <DismissButton
          onClick={() => {
            setIsVisible(false)
            sessionStorage.setItem('hasDismissedPriceDrop', 'true')
            trackEvent({ action: GA_EVENT.PriceDropDismiss })
          }}
          aria-label={t('home:price_drop.dismiss_label')}
        >
          <CloseIcon aria-hidden />
        </DismissButton>
      </PriceDropContainer>
    )
  )
}
